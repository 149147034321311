import React from 'react'
import Layout from "../components/layouts/Layout";
import backgroundHex from "../assets/img/backgroundSections/background.webp";
import ClaimPeach from '../components/ClaimPeach/ClaimPeach';
export default function claimPeach() {
    return (
        <Layout background={backgroundHex}>
            <ClaimPeach />
        </Layout>
    )
}
