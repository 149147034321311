import React from 'react'
import peachCoin from "../../assets/vector/peachCoin.svg";
import Button from "../../components/elements/button";


export default function ClaimPeach() {
    return (

        <>
            <div className=' text-lg  flex justify-center pt-20'>

                <span className="font-bold   text-primaryDark text-lg">$PEACH<span className="text-primaryDark text-lg"> Claim</span> </span>
                <img className="w-9 " src={peachCoin} />


            </div>

            <div className='flex  items-center pt-10 justify-between p-10 px-5 md:px-20 flex-col md:flex-row'>

                <div className='bg-[#F0C1DC] w-full p-5 m-5 mr-10 rounded-lg shadow-xl'>

                    <h3 className='text-[#AA2E74] font-bold text-lg text-center'>ABOUT CLAIM</h3>
                    <div>

                        <div className='flex items-center mt-4 '>
                            <div className='w-[.4rem] h-[.4rem] rounded-[4rem] bg-[#4A4B5A] border '></div><h4 className='text-[#4A4B5A] text-md ml-2'>Total Tokens purchased: 350 $PEACH</h4>
                        </div>

                        <div className='flex items-center mt-1'>
                            <div className='w-[.4rem] h-[.4rem] rounded-[4rem] bg-[#4A4B5A] border '></div><h4 className='text-[#4A4B5A] text-md ml-2'> in 4 months.</h4>
                        </div>

                        <div className='flex items-center mt-1'>
                            <div className='w-[.4rem] h-[.4rem] rounded-[4rem] bg-[#4A4B5A] border '></div><h4 className='text-[#4A4B5A] text-md ml-2'>Date of purchase: 18 02 2022</h4>
                        </div>

                    </div>
                    <div>
                        <h3 className='text-[#AA2E74] text-md  font-semibold mt-5 text-center'>Tokens Withdrawable</h3>

                        <div className='flex text-[#AA2E74] text-lg mt-3 justify-between'>
                            <div> <span className='px-5' >PER DAY:</span><p className='text-center'>1,31</p></div>
                            <div className='w-[1.7px] h-[3.5rem] bg-[#AA2E74]'> </div>
                            <div> <span className='px-5'>PER MONTH:</span><p className='text-center'>39</p></div>


                        </div>


                    </div>
                </div>



                <div className="w-full ml-0 md:ml-10 mt-10 md:mt-0 ">

                    <span className='text-[#4D4D4D] font-semibold text-md'>Withdraw Tokens</span>

                    <div className="w-full ">
                        <div className='text-[#4D4D4D] text-sm text-right w-full md:w-4/5 font-semibold'>Withdrawable: 100,37</div>

                        <div className='bg-[#FFFFFF] p-2 rounded-md w-4/5 shadow-lg'>
                            <div className='flex justify-between '>
                                <input placeholder='0' className='w-3/5 outline-none font-semibold ' />
                                <div className="flex items-center">
                                    <h3 className='text-[#4D4D4D] text-md mr-2'>$PEACH</h3>
                                    <button className="claim_btn h-[1.3rem] hover:scale-105 transition duration-150 ease-out pb-2 font-semibold">MAX</button>
                                </div>

                            </div>

                        </div>

                        <button className="claim_btn h-[2rem] hover:scale-105 transition duration-150 ease-out pb-2 m-auto mt-5 font-bold  ">Withdraw</button>


                    </div>
                </div>



            </div>




        </>
    )
}
